import React from 'react'
import clsx from 'clsx'
import Datepicker, {
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker'

import FormElement from '~ui/FormElement'

import type { DateInputProps } from './date-input'

// forwardedRef is HTMLDivElement bc DateInput doesn't have native support for refs
// so we use FormElement as a work around to be able to use ref.current.querySelector('input')
// to be able to get the input field
export const DateInput = React.forwardRef<HTMLDivElement, DateInputProps>(
  (
    {
      name,
      value,
      label,
      error,
      required,
      labelBgColor,
      helperText,
      containerClassName,
      onChange,
      children,
      ...props
    },
    ref,
  ) => {
    const val = value as string
    const [date, setDate] = React.useState<{
      startDate: DateType
      endDate: DateType
    }>(
      !val?.length
        ? { startDate: null, endDate: null }
        : {
            startDate: typeof val === 'string' ? new Date(val) : val,
            endDate: typeof val === 'string' ? new Date(val) : val,
          },
    )

    const handleChange = React.useCallback(
      (value: DateValueType) => {
        const val = value?.startDate
        if (onChange) {
          onChange(val?.toISOString() || '')
        }
        setDate(
          value
            ? {
                startDate: value.startDate,
                endDate: value.startDate,
              }
            : {
                startDate: null,
                endDate: null,
              },
        )
      },
      [onChange],
    )

    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        className={containerClassName}
        ref={ref}
      >
        <Datepicker
          inputName={name}
          value={date}
          useRange={false}
          asSingle
          startFrom={props.startFrom}
          minDate={props.minDate}
          maxDate={props.maxDate}
          disabledDates={props.disabledDates}
          popoverDirection={props.popoverDirection}
          // placeholder="MM/DD/YYYY"
          // displayFormat="MM/DD/YYYY"
          onChange={handleChange}
          inputClassName={clsx(
            'relative pl-2.5 pr-14 py-2.5 w-full text-sm text-gray-900 dark:text-slate-400 bg-white dark:bg-slate-900 rounded-lg border-1',
            'appearance-none dark:text-gray',
            'dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-600 peer',
            {
              'border-error-500 focus:border-error-500': !!error,
              'border-gray-300 dark:border-gray-600': !error,
            },
            props.className,
          )}
        />
      </FormElement>
    )
  },
)
