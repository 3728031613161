import React, { useCallback, useEffect, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { useControlField, useField } from 'remix-validated-form';

import FormElement from '~ui/FormElement';

import { DateTimeInputProps } from './date-time-input';

import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";
import { format } from 'date-fns-tz';

interface SimplifiedDateTimeInputProps extends DateTimeInputProps {
  formatDate?: string;
}

const formatDateForSubmission = (date: Date) => {
  // Always return 'YYYY-MM-DD' for backend submission
  return date.toISOString().split('T')[0];
};

export const DateTimeInput = React.forwardRef<
  HTMLInputElement,
  SimplifiedDateTimeInputProps>(

  (
  {
    name,
    value,
    label,
    required,
    labelBgColor,
    helperText,
    description,
    containerClassName,
    onChange,
    formatDate,
    ...props
  },
  ref) =>
  {
    const { error, getInputProps, validate, defaultValue } = useField(name);

    const [val, setVal] = useState<{
      startDate: Date | null;
      endDate: Date | null;
    }>({
      startDate: value ? new Date(value) : null,
      endDate: value ? new Date(value) : null
    });

    const handleChange = useCallback(
      async (date: Date | null) => {
        setVal({
          startDate: date || null,
          endDate: date || null
        });
        if (onChange) {
          const formattedDate = date ? formatDateForDisplay(date) : '';
          onChange(formattedDate);
        }
        await validate();
      },
      [onChange, formatDate, validate]
    );

    const formatDateForDisplay = (date: Date) => {
      if (formatDate) {
        return format(date, formatDate);
      } else {
        return date.toISOString().split('T')[0];
      }
    };

    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        description={description}
        className={containerClassName}>

        <Datepicker
          value={val}
          onChange={(newValue) =>
          handleChange(
            newValue?.startDate ? new Date(newValue.startDate) : null
          )
          }
          useRange={false}
          asSingle={true}
          displayFormat={formatDate ? formatDate : 'YYYY-MM-DD'}
          inputClassName="border-gray-300 focus:border-primary-600 dark:border-gray-500 dark:focus:border-slate-300 bg-white text-gray-900 dark:bg-slate-900 dark:focus:bg-slate-900 dark:text-slate-400 w-full py-2.5 text-sm rounded-md  px-2.5 relative" />

        <input
          type="hidden"
          name={name}
          value={val.startDate ? val.startDate.toISOString() : ''}
          ref={ref} />

      </FormElement>);

  }
);